import { Box, Skeleton, Stack } from "@mui/material";
import { Sections } from "@/shared/components";

function ArticleLoaderComponent() {
  return (
    <Sections bgcolor="white">
      <Box p="100px 0px">
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            maxWidth: { xs: 196, md: 380 },
            height: 20,
            mx: "auto",
          }}
        />

        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            maxWidth: "1012px",
            height: 75,
            mx: "auto",
            mt: "40px",
          }}
        />

        <Box mt={{ xs: "100px", md: "212px" }}>
          <Box mb="48px">
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                maxWidth: { xs: 141, md: 517 },
                height: 30,
                mb: { xs: "48px" },
              }}
            />

            <Stack spacing={"10px"}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "85%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "95%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "88%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "92%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "88%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "30%",
                  height: 20,
                }}
              />
            </Stack>
          </Box>

          <Box>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                maxWidth: { xs: 141, md: 517 },
                height: 30,
                mb: { xs: "48px" },
              }}
            />

            <Stack spacing={"10px"}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "85%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "95%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "88%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "92%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "88%",
                  height: 20,
                }}
              />

              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  maxWidth: "30%",
                  height: 20,
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </Sections>
  );
}

export default ArticleLoaderComponent;
