"use client";
import React, { useEffect } from "react";
import { useAtom } from "jotai";
import Client from "@/lib/http-client";
import UserApi from "@/shared/api/user";
import { StoreType, userStore } from "@/store/user.store";
import { deleteCookie } from "cookies-next";

type Props = {
  token: string;
  resource_token: string;
  custom_token: string;
  children: React.ReactNode;
};

const UserProvider = ({
  token,
  resource_token,
  custom_token,
  children,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUser] = useAtom<StoreType>(userStore);

  useEffect(() => {
    const fetchUserData = async () => {
      Client.setAccessToken(token);
      Client.setAccessResource(resource_token);
      Client.setCustomToken(custom_token);

      if (token) {
        try {
          const { data } = await UserApi.getUserInfo();
          setUser({
            isAuth: true,
            user: {
              id: data.id,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              role: data.role,
              status: data.status,
              company_name: data.company_name,
              job_title: data.job_title,
              attach_mailing: data.attach_mailing,
            },
          });
        } catch {
          deleteCookie("token", {
            expires: new Date(Date.now() - 1),
          });
        }
      }
    };

    fetchUserData();
  }, [setUser, token, resource_token]);

  return children;
};

export default UserProvider;
