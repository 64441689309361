import colors from "@/lib/palette";
import { Badge, Button } from "@/shared/components";
import { Box, SxProps } from "@mui/material";

const SelectedFilterItems = ({
  value,
  sx,
  isAlwaysShow,
  onSelect,
  onClear,
}: {
  value: string[];
  sx?: SxProps;
  isAlwaysShow?: boolean;
  onSelect: (value: string[]) => void;
  onClear?: () => void;
}) => {
  const onRemove = (keyword: string) => {
    const filtedValue = value.filter((item) => item !== keyword) || [];

    onSelect(filtedValue);
  };
  if (!isAlwaysShow && !value.length) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gap={"4px"}
      mb={2}
      flexWrap={"wrap"}
      sx={sx}
    >
      <Button
        variant="outlined-primary"
        height="auto"
        width="84px"
        sx={{
          borderRadius: "4px",
          fontSize: {
            xs: "12px",
          },
          padding: "10px 12px",
          letterSpacing: "-0.32px",
          backgroundColor: colors.white,
        }}
        onClick={onClear}
      >
        Clear all
      </Button>

      {value.map((item) => (
        <Badge key={item} cancable onClick={() => onRemove(item)}>
          {item}
        </Badge>
      ))}
    </Box>
  );
};

export default SelectedFilterItems;
