import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import colors from "@/lib/palette";

import { Icon } from "@/shared/components";

type Props = {
  children: React.ReactNode;
  selected?: boolean;
  cancable?: boolean;
  onClick?: () => void;
  sx?: SxProps;
};

const BadgePrimaryComponent = ({
  children,
  selected,
  cancable,
  sx,

  onClick,
}: Props) => {
  return (
    <Typography
      component="div"
      border={`1px solid ${selected ? colors.blue : "#E6E9ED"}`}
      bgcolor={selected ? colors.blue : colors.white}
      borderRadius="4px"
      p="10px"
      color={selected ? colors.white : colors.blue}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        whiteSpace: "pre-wrap",
        lineHeight: "0.875em",
        transition: "all 0.2s",
        fontSize: {
          xs: "12px",
        },
        "&:hover": {
          backgroundColor: colors.blue,
          "& span, & svg": {
            color: colors.white,
            fill: colors.white,
          },
        },
        // "@media (hover: none)": {
        //   "&:hover": {
        //     backgroundColor: "inherit",
        //     "& span, & svg": {
        //       color: "inherit",
        //       fill: "inherit",
        //     },
        //   },
        // },
        ...sx,
      }}
      onClick={onClick}
    >
      <Box component="span" display="flex" alignItems="center">
        {children}
        <Icon
          icon={selected || cancable ? "close" : "more"}
          width={"12px"}
          height={"12px"}
          color={selected && cancable ? colors.white : colors.black500}
          sx={{
            ml: "4px",
          }}
        />
      </Box>
    </Typography>
  );
};

export default BadgePrimaryComponent;
