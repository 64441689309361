import React from "react";
import { Box, SxProps } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system/styleFunctionSx";
import colors from "@/lib/palette";
import Image from "next/image";

type Props = {
  logo?: string;
  compnayName?: string;
  width?: ResponsiveStyleValue<string | number>;
  height?: ResponsiveStyleValue<string | number>;
  iconSize?: {
    width?: ResponsiveStyleValue<string | number>;
    height?: ResponsiveStyleValue<string | number>;
  };
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  sx?: SxProps;
};

const CompnayLogoComponent = (props: Props) => {
  const { logo, compnayName, iconSize, width, append, prepend, sx } = props;
  return (
    <Box
      maxWidth={width}
      height={"auto"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bgcolor={colors.blue10}
      borderRadius={2.5}
      sx={{ ...sx }}
    >
      {prepend}
      <Box
        width={iconSize?.width || "41%"}
        height={iconSize?.height}
        display="flex"
        maxWidth={iconSize?.width || "41%"}
        flex="1 0 auto"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image
          src={logo || "/icons/company-default-img.png"}
          loading="lazy"
          blurDataURL="/icons/company-default-img.png"
          placeholder="blur"
          alt={compnayName || "Company Logo"}
          className="next-image"
          width={100}
          height={100}
          style={{
            objectFit: "contain",
          }}
        />
      </Box>
      {append}
    </Box>
  );
};

export default CompnayLogoComponent;
