"use client";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import colors from "@/lib/palette";
import theme from "@/lib/theme";

import {
  Sections,
  LoaderResourceCard,
  Paginations,
  Badge,
  Button,
  SearchInput,
  Icon,
} from "@/shared/components";
import KeywordsSearching from "@/shared/features/keywords-searching.feature";
import ResourceTypesDropdown from "../resource-types-dropdown.feature";

import ResourcesApi, {
  type ResourceModelType,
  type FetchResourcesParamsType,
  ResourceTypeModelType,
} from "@/shared/api/resources";
import { FiltersModal } from "@/shared/modals";

import SelectedFilterItems from "./components/selected-filter-items";
import ResourcesList from "./components/resources-list";
import Link from "next/link";

type Props = {
  title: string;
  companyId?: number;
  hidePagination?: boolean;
  hideItemsCount?: boolean;
  hideCatalogLink?: boolean;
};

function ResourcesMicroBlockFeature({
  title,
  companyId,
  hidePagination,
  hideItemsCount,
  hideCatalogLink,
}: Props) {
  const isMobileScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down("md")
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [isClearKeywords, setIsClearKeywords] = useState<boolean>(false);
  const [resources, setResources] = useState<ResourceModelType[]>([]);
  const [types, setTypes] = useState<ResourceTypeModelType[]>([]);
  const [typesSearch, setTypesSearch] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [params, setParams] = useState<FetchResourcesParamsType>({
    exactKeywordsSearch: true,
    query: "",
    page: "1",
    pageCount: "12",
    companyId,
    keywords: "",
    resourceType: "",
  });

  const memoParams = useMemo(() => params, [params]);
  const memoPages = useMemo(
    () => Math.ceil(total / Number(params.pageCount)),
    [params.pageCount, total]
  );
  const memoTypes = useMemo(
    () =>
      types.filter((type) =>
        type.name.toLowerCase().includes(typesSearch.toLowerCase())
      ),
    [types, typesSearch]
  );
  const memoCatalogLink = useMemo<string>(() => {
    return `/library?page=1&pageCount=12&type=resources${
      memoParams.keywords ? `&keywords=${memoParams.keywords}` : ""
    }${
      memoParams.resourceType ? `&resourceType=${memoParams.resourceType}` : ""
    }`;
  }, [memoParams.keywords, memoParams.resourceType]);

  const isSelectedFilters = useMemo<boolean>(() => {
    return !!memoParams.keywords || !!memoParams.resourceType;
  }, [memoParams.keywords, memoParams.resourceType]);

  const handleSelectKeyowrds = (value: string[]) => {
    setParams((prev) => ({ ...prev, keywords: value.join(","), page: "1" }));
  };

  const handleSelectTypes = (value: string) => {
    if (memoParams.resourceType?.split(",").includes(value)) {
      setParams((prev) => ({
        ...prev,
        resourceType: memoParams.resourceType
          ?.split(",")
          .filter((type) => type !== value)
          .join(","),
        page: "1",
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        resourceType: memoParams.resourceType
          ? `${memoParams.resourceType},${value}`
          : value,
        page: "1",
      }));
    }
  };

  const handleChangePage = (page: string) => {
    setParams((prev) => ({ ...prev, page: page.toString() }));

    const companyResources = document.querySelector(".micro-resources");

    if (companyResources) {
      companyResources.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleClearAll = () => {
    setParams((prev) => ({
      ...prev,
      keywords: "",
      resourceType: "",
      page: "1",
    }));

    setIsClearKeywords(true);

    const timeout = setTimeout(() => {
      setIsClearKeywords(false);
      clearTimeout(timeout);
    }, 100);
  };

  const handleResetAllFilters = () => {
    setParams((prev) => ({
      ...prev,
      keywords: "",
      resourceType: "",
      page: "1",
    }));
  };

  useEffect(() => {
    const fetchResourceTypes = async () => {
      const { data } = await ResourcesApi.fetchResourceTypes({
        pageCount: "10000",
        withResourceCounting: true,
        ...(companyId ? { companyId } : {}),
      });
      setTypes(data);
    };

    fetchResourceTypes();
  }, []);

  const fetchResources = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_resources } = await ResourcesApi.fetchResourcesList(
          memoParams,
          signal
        );

        if (Array.isArray(data)) {
          setResources(data);
        }

        setTotal(total_resources);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error && error.message === "Request was aborted") {
          return;
        }
        setLoading(false);
      }
    },
    [memoParams]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchResources(signal);

    return () => {
      controller.abort();
    };
  }, [memoParams, fetchResources]);

  if (!resources.length && !isSelectedFilters && !loading) {
    return null;
  }

  return (
    <Sections className="micro-resources">
      <Box
        display="flex"
        alignItems="center"
        marginBottom={{ xs: 3.5, md: 4.5 }}
      >
        <Box display="flex" alignItems="center" width={"100%"}>
          <Typography
            component="h2"
            variant="h2"
            display="flex"
            alignItems="center"
            mr="auto"
          >
            {title}

            {!hideItemsCount ? (
              <Typography
                component="span"
                fontSize="22px"
                ml={{ xs: 1, md: 2 }}
                mt="6px"
                display={{ xs: "none", md: "block" }}
                flex="1 0 auto"
                sx={{ fontWeight: 400 }}
              >
                {total || 0} {total === 1 ? "item" : "items"}
              </Typography>
            ) : null}
          </Typography>

          {isMobileScreen ? (
            <FiltersModal
              loading={!!loading}
              total_items={total}
              iHasFilters={!!(memoParams.keywords || memoParams.resourceType)}
              onClearFilters={handleResetAllFilters}
            >
              <Box mb={3}>
                <Typography variant="h4" mb={1.5}>
                  Search by keyword
                </Typography>

                <KeywordsSearching
                  value={memoParams.keywords?.split(",").filter(Boolean) || []}
                  onSelect={handleSelectKeyowrds}
                  companyId={companyId}
                  sx={{ width: "100%" }}
                />

                <SelectedFilterItems
                  value={memoParams.keywords?.split(",").filter(Boolean) || []}
                  onSelect={handleSelectKeyowrds}
                  sx={{ mt: 2 }}
                />
              </Box>

              <Box>
                <Typography variant="h4" mb={1.5}>
                  Resource type
                </Typography>

                <SearchInput
                  value={typesSearch}
                  onInput={setTypesSearch}
                  placeholder="Search for resource type"
                />

                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  columnGap={1}
                  rowGap={1}
                  mt={2}
                >
                  {memoParams.resourceType && (
                    <Button
                      variant="outlined-primary"
                      height="auto"
                      width="84px"
                      sx={{
                        borderRadius: "4px",
                        fontSize: {
                          xs: "12px",
                        },
                        padding: "10px 12px",
                        letterSpacing: "-0.32px",
                        backgroundColor: colors.white,
                      }}
                      onClick={() => {
                        setParams((prev) => ({
                          ...prev,
                          resourceType: "",
                          page: "1",
                        }));
                      }}
                    >
                      Reset all
                    </Button>
                  )}

                  {memoTypes.map((type) => (
                    <Badge
                      key={type.id}
                      selected={memoParams.resourceType
                        ?.split(",")
                        .filter(Boolean)
                        .includes(type.value)}
                      cancable={memoParams.resourceType
                        ?.split(",")
                        .filter(Boolean)
                        .includes(type.value)}
                      onClick={() => handleSelectTypes(type.value)}
                    >
                      {type.name}
                    </Badge>
                  ))}
                </Box>
              </Box>
            </FiltersModal>
          ) : (
            <>
              <KeywordsSearching
                value={memoParams.keywords?.split(",").filter(Boolean) || []}
                onSelect={handleSelectKeyowrds}
                clear={!!isClearKeywords}
                companyId={companyId}
                sx={{
                  width: "100%",
                  maxWidth: "280px",
                  ml: 2,
                  height: "41px",
                }}
              />

              <ResourceTypesDropdown
                value={
                  memoParams.resourceType?.split(",").filter(Boolean) || []
                }
                types={types}
                onSelect={(filter, value) => {
                  setParams((prev) => ({
                    ...prev,
                    [filter]: value,
                    page: "1",
                  }));
                }}
                selectSx={{
                  height: "41px",
                  borderRadius: "4px",
                }}
                sx={{
                  ml: 2,
                }}
              />
            </>
          )}
        </Box>
      </Box>

      {!isMobileScreen && (
        <SelectedFilterItems
          value={memoParams.keywords?.split(",").filter(Boolean) || []}
          onSelect={handleSelectKeyowrds}
          isAlwaysShow={isSelectedFilters}
          onClear={handleClearAll}
        />
      )}

      <Box>
        <Box
          component="div"
          display="grid"
          gridTemplateColumns={{
            xs: "1fr",
            sm: "repeat(auto-fill, minmax(290px, 1fr))",
          }}
          columnGap={{ xs: 2, md: 2.5 }}
          rowGap={{ xs: 2, md: 2.5 }}
        >
          {loading ? (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                <LoaderResourceCard key={index} />
              ))}
            </>
          ) : (
            <ResourcesList resources={resources} />
          )}
        </Box>
      </Box>

      {!hidePagination && memoPages > 1 && (
        <Paginations
          count={Math.ceil(total / Number(params.pageCount))}
          page={Number(params.page)}
          onChangePage={handleChangePage}
        />
      )}

      {!hideCatalogLink && (
        <Link href={memoCatalogLink}>
          <Button
            variant="outlined-primary"
            width={{
              xs: 185,
              sm: 208,
              xl: 255,
            }}
            sx={{ margin: "4rem auto 0" }}
            append={
              <Icon
                icon="arrow-right"
                width={{ xs: 14, sm: 20 }}
                height={{ xs: 14, sm: 20 }}
                color={colors.blue}
              />
            }
          >
            Show all resources
          </Button>
        </Link>
      )}
    </Sections>
  );
}

export default ResourcesMicroBlockFeature;
