import React from "react";
import Link from "next/link";
import { useAtom } from "jotai";
import { Typography, Box } from "@mui/material";
import { triggerAtom } from "@/store/trigger.store";
import { Button, Icon } from "@/shared/components";
import { IconsNames } from "@/shared/components/icon.component/icon.types";
import colors from "@/lib/palette";

type Props = {
  open: boolean;
  isAuth?: boolean;
  onOpenLoginModal: () => void;
  onOpenSingUpModal: () => void;
  onClose: () => void;
  onLogout: () => void;
};

type LinkItemType = {
  text: string;
  icon: IconsNames;
  disabled?: boolean;
  onClick?: () => void;
};

const links: (LinkItemType & { href: string })[] = [
  // {
  //   href: "/library?type=resources&pageCount=12",
  //   text: "Library",
  //   icon: "library",
  // },

  {
    href: "/technologies",
    text: "Technologies",
    icon: "categories",
  },

  {
    href: "/",
    text: "For Business",
    icon: "business",
    disabled: true,
  },
];

const BurgerItem = ({ icon, text, disabled, onClick }: LinkItemType) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    sx={{
      cursor: "pointer",
      opacity: disabled ? 0.5 : 1,
      pointerEvents: disabled ? "none" : "auto",
    }}
  >
    <Button
      width={"36px"}
      height={"36px"}
      variant="outlined-primary"
      sx={{ borderRadius: "4px" }}
      onClick={onClick}
    >
      <Icon icon={icon} width={"16px"} height={"16px"} color={colors.blue} />
    </Button>

    <Typography
      variant="subtitle1"
      fontWeight={"&00"}
      sx={{ marginLeft: "16px" }}
    >
      {text}
    </Typography>
  </Box>
);

const BurgerMenuComponent = ({
  open,
  isAuth = false,
  onClose,
  onOpenLoginModal,
  onOpenSingUpModal,
  onLogout,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setTrigger] = useAtom<boolean>(triggerAtom);

  const handleOpenModal = () => {
    onOpenLoginModal();
    onClose();
  };

  const handleOpenSingUpModal = () => {
    onOpenSingUpModal();
    onClose();
  };

  const handleLogout = () => {
    onLogout();
    onClose();
  };

  const handleRedirectToLibrary = () => {
    onClose();
    const timeout = setTimeout(() => {
      setTrigger(true);

      clearTimeout(timeout);
    }, 500);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          maxWidth: "320px",
          width: "100%",
          height: "calc(100% - 76px)",
          right: 0,
          top: "76px",
          backgroundColor: "white",
          transform: open ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.2s ease-in-out",
          zIndex: 101,
          padding: "36px 28px",
        }}
      >
        <Box display={"grid"} rowGap={"24px"}>
          <Link
            href="/library?type=resources&pageCount=12"
            style={{ display: "block", textDecoration: "none" }}
            onClick={handleRedirectToLibrary}
          >
            <BurgerItem icon={"library"} text={"Library"} />
          </Link>

          {links.map((link) =>
            link.disabled ? (
              <Box key={link.href} display="flex" alignItems="center">
                <BurgerItem {...link} />

                <Typography
                  variant="subtitle1"
                  fontSize={{
                    xs: "10px",
                  }}
                  color={colors.blue50}
                  border={`1px solid ${colors.blue50}`}
                  borderRadius={"12px"}
                  p={"4px 8px"}
                  sx={{ marginLeft: "16px" }}
                >
                  SOON
                </Typography>
              </Box>
            ) : (
              <Link
                href={link.href}
                key={link.href}
                style={{ display: "block", textDecoration: "none" }}
                onClick={onClose}
              >
                <BurgerItem {...link} />
              </Link>
            )
          )}
        </Box>

        <Box
          marginTop={"auto"}
          display={isAuth ? "grid" : "flex"}
          rowGap={"24px"}
          alignItems={"center"}
        >
          {isAuth ? (
            <>
              <Link
                href={"/profile"}
                style={{ display: "block" }}
                onClick={onClose}
              >
                <BurgerItem icon={"user"} text={"Profile"} />
              </Link>

              <BurgerItem
                icon={"logout"}
                text={"Log out"}
                onClick={handleLogout}
              />
            </>
          ) : (
            <>
              <Button
                variant="outlined-primary"
                height={"36px"}
                sx={{ marginRight: "16px", borderRadius: "4px" }}
                onClick={handleOpenModal}
              >
                Sign in
              </Button>

              <Button
                height={"36px"}
                variant="filled"
                sx={{ borderRadius: "4px" }}
                onClick={handleOpenSingUpModal}
              >
                Sign up
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99,
          display: open ? "block" : "none",
        }}
        onClick={onClose}
      />
    </>
  );
};

export default BurgerMenuComponent;
