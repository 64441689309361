import Link from "next/link";
import {
  Card,
  Box,
  CardMedia,
  Typography,
  CardContent,
  Stack,
} from "@mui/material";

import { Badge } from "@/shared/components";
import { formatNumberWithCommas } from "@/shared/utils";
import { type CompanyModelType } from "@/shared/api/companies";

import colors from "@/lib/palette";

export default function CompanyCardComponent({
  company,
  onClickBadge,
}: {
  company: CompanyModelType;
  onClickBadge?: () => void;
}) {
  const resourcesCount = company.resources_count
    ? formatNumberWithCommas(String(company.resources_count))
    : 0;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "81px 1fr",
          md: "217px 1fr",
        },
        columnGap: {
          xs: 2,
          md: 0,
        },
        position: "relative",
        borderRadius: "12px",
        border: `1px solid ${colors.black100}`,
        padding: {
          xs: "16px",
          md: "0",
        },
      }}
    >
      <Box
        sx={{
          gridRow: {
            md: "span 2",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: {
            xs: "80px",
            md: "217px",
          },
          height: {
            xs: "70px",
            md: "auto",
          },
          padding: "14px",
          width: "100%",
          background: colors.blue10,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "100%",
              md: "118px",
            },

            width: "100%",
          }}
        >
          <Link
            href={`/company/${company.id}`}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            <CardMedia
              component="img"
              alt={company.name || "Company"}
              sx={{
                height: "auto",
                display: "block",
                maxWidth: {
                  xs: "100%",
                  md: "118px",
                },
              }}
              image={company.image_path || "/icons/company-default-img.png"}
            />
          </Link>
        </Box>
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",

          padding: {
            xs: "0 0 0 0 !important",
            md: "24px 20px 0px !important",
            xl: "32px 40px 0px !important",
          },
        }}
      >
        <Box
          display={{
            xs: "block",
            md: "none",
          }}
        >
          <Link href={`/company/${company.id}`} style={{ color: "inherit" }}>
            <Typography
              component="h4"
              variant="h4"
              fontWeight={700}
              mb={0.5}
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "pre-wrap",
              }}
            >
              {company.name}
            </Typography>
          </Link>
        </Box>
        <Stack
          direction="row"
          mb={1}
          spacing={{
            xs: 0,
            md: 1,
          }}
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            display={{
              xs: "none",
              md: "block",
            }}
          >
            <Link href={`/company/${company.id}`} style={{ color: "inherit" }}>
              <Typography
                component="h4"
                variant="h4"
                fontWeight={700}
                display={"inline-block"}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "pre-wrap",
                }}
              >
                {company.name}
              </Typography>
            </Link>
          </Box>

          {resourcesCount ? (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },

                  flex: "1 0 4px",
                  maxWidth: "4px",
                  width: "4px",
                  height: "4px",
                  borderRadius: "100%",
                  background: colors.black500,
                }}
              />
              <Typography
                flex="1 0 auto"
                component="span"
                variant="subtitle1"
                fontWeight={400}
                fontSize={{
                  xs: "10px",
                  md: "14px",
                }}
              >
                {resourcesCount}{" "}
                {company.resources_count === 1 ? "Resource" : "Resources"}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Stack>

        <Box
          mb={{
            xs: 0,
            md: 2,
          }}
          sx={{
            maxWidth: {
              xs: "none",
              md: "545px",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
            fontWeight={400}
            color={colors.black700}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
              overflowWrap: "anywhere",
            }}
          >
            {company.description || "No description"}
          </Typography>
        </Box>
      </CardContent>

      <Box
        sx={{
          // display: 'flex',
          gap: 1,
          flexWrap: "wrap",
          gridColumn: {
            xs: "1 / 3",
            md: "auto",
          },

          padding: {
            xs: "16px 0 0 0 !important",
            md: "0px 20px 24px !important",
            xl: "0px 40px 32px!important",
          },

          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          whiteSpace: "pre-wrap",
        }}
      >
        {company.categories?.map((category) => (
          <Box
            key={`category_${category.value}`}
            sx={{
              display: "inline-block",
              mr: 0.5,
              mb: 0.5,
            }}
          >
            <Badge
              variant="tertiary"
              href={`/library?type=companies&page=1&pageCount=12&categories=${category.value}`}
              onClick={onClickBadge}
            >
              {category.name}
            </Badge>
          </Box>
        ))}
      </Box>
    </Card>
  );
}
