// import React from "react";
import Link from "next/link";
import { Box, Typography } from "@mui/material";
import { CompanyModelType } from "@/shared/api/companies";
import { CompnayLogo } from "@/shared/components";

type Props = {
  companies: CompanyModelType[];
};

const MicroCompaniesSectionComponent = ({ companies }: Props) => {
  return (
    <Box
      display="grid"
      width={"100%"}
      sx={{
        gridTemplateColumns: {
          xs: "1fr",
          md: "repeat(auto-fill, minmax(300px, 1fr))",
        },
        gridTemplateRows: {
          xs: "1fr",
          md: "minmax(282px, 1fr) repeat(auto-fit, minmax(282px, 1fr))",
        },
        rowGap: { xs: 1, md: 2.5 },
        columnGap: { xs: 1, md: 2.5 },
      }}
    >
      {companies.map((company) => {
        return (
          <Link
            key={company.id}
            href={`/company/${company.id}`}
            style={{ textDecoration: "none", display: "block", height: "100%" }}
          >
            <CompnayLogo
              logo={company.image_path || ""}
              compnayName={company.name}
              iconSize={{
                width: { xs: "44px", md: "110px" },
                height: { xs: "44px", md: "110px" },
              }}
              sx={{
                padding: { xs: 2.25, md: "53px 16px 40px" },
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                height: "100%",
                justifyContent: "stretch",
              }}
              append={
                <Typography
                  component="h3"
                  textAlign={{ xs: "left", md: "center" }}
                  marginTop={{ md: 5 }}
                  ml={{ xs: 5, md: 0 }}
                  whiteSpace={{ xs: "normal" }}
                  sx={{
                    fontSize: { xs: "20px", md: "28px" },
                    fontWeight: 700,
                    wordBreak: "break-word",
                  }}
                >
                  {company.name}
                </Typography>
              }
            />
          </Link>
        );
      })}
    </Box>
  );
};

export default MicroCompaniesSectionComponent;
