import { http } from "@/lib/http-client";
import { GeneralResponseModelType } from "@/shared/types/response.types";
import {
  SearchModelType,
  SearchParamsModelType,
  CompanySearchModelType,
  ResourceSearchModelType,
  TechnologySearchModelType,
} from "../search.types";

const search = async (
  params?: SearchParamsModelType,
  signal?: AbortSignal
): Promise<GeneralResponseModelType<SearchModelType>> => {
  return http.get("/opensearch", params, signal);
};

const methods = {
  search,
};

export type {
  SearchParamsModelType,
  SearchModelType,
  CompanySearchModelType,
  ResourceSearchModelType,
  TechnologySearchModelType,
};

export default methods;
