import { http } from "@/lib/http-client";
import { GeneralResponseModelType } from "@/shared/types/response.types.d";

import type {
  FetchKeywordsParamsType,
  KeywordModelType,
} from "./keywords.types";

const fetchKeywordsList = async (
  params?: FetchKeywordsParamsType,
  signal?: AbortSignal
): Promise<GeneralResponseModelType<KeywordModelType[]>> => {
  return http.get(
    "/keywords/",
    {
      isActive: true,
      ...params,
    },
    signal
  );
};

const methods = {
  fetchKeywordsList,
};

export default methods;
