import { http } from "@/lib/http-client";

import {
  ConfirmtMethod,
  RegisterMethod,
  SignInMethod,
  SignUpForm,
  ResetPasswordMethod,
  CreateNewPasswordMethod,
  ResetOtpCodeMethodType,
} from "./../types.d";

export const signUp: RegisterMethod = async (form) => {
  return http.post("/user/register", form);
};

export const signIn: SignInMethod = async (form) => {
  return http.post("/user/login", form);
};

export const activateAccount: ConfirmtMethod = async (form) => {
  return http.post("/activate-profile", form);
};

export const resetPassword: ResetPasswordMethod = async (form) => {
  return http.post("/password-reset", form);
};

export const createNewPassword: CreateNewPasswordMethod = async (form) => {
  return http.post("/password-reset-confirm", form);
};

export const resetOtpCode: ResetOtpCodeMethodType = async (form) => {
  return http.post("/reset-otp", form);
};

const methods = {
  signUp,
  signIn,
  activateAccount,
  resetPassword,
  resetOtpCode,
  createNewPassword,
};

export type { SignUpForm };

export default methods;
