class HttpClient {
  private baseUrl: string;
  private "X-Access-Resource": string | undefined;
  private "X-Access-Token": string | undefined;
  private "custom_token": string | undefined;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this["X-Access-Resource"] = undefined;
    this["X-Access-Token"] = undefined;
    this.custom_token = undefined;
  }

  private handleErrorMessage = (message: string): string => {
    try {
      console.log("error:", message);

      const error = JSON.parse(message);

      if (error === "NOT FOUND") {
        return "Opps, this object not found, please try again later.";
      }

      if (typeof error === "string") {
        return error;
      }

      if (error.detail && typeof error.detail === "string") {
        return error.detail;
      }
      if (
        error.detail &&
        typeof error.detail === "object" &&
        Object.keys(error.detail).length > 0
      ) {
        console.log("Error message:", error);
        return (
          error.detail.non_field_error ||
          "Something went wrong, please try again later"
        );
      }

      return "Went wrong, please try again later.";
    } catch (parseError) {
      return "Failed to parse error data: " + parseError;
    }
  };

  private async request<T>(url: string, options: RequestInit): Promise<T> {
    const headers = new Headers(options.headers);

    headers.set("Content-Type", "application/json");

    if (this.custom_token) {
      headers.set("api-access-token", this.custom_token);
    }

    headers.set(
      "x-custom-header",
      "D7*ly?'?nK?F?N!::e#QFUVg2i`v:cvFL/Y=&SW_2R^8DzA)G]"
    );

    try {
      const response = await fetch(url, {
        headers,
        ...options,
      });

      if (response.ok) {
        return await response.json();
      }

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(
          JSON.stringify(errorData || "Network response was not ok")
        );
      }
    } catch (error) {
      if ((error as Error).name === "AbortError") {
        throw new Error("Request was aborted");
      } else {
        if (error instanceof Error) {
          if (error.message) {
            throw new Error(this.handleErrorMessage(error.message));
          }
        } else {
          throw new Error("Something went wrong");
        }
      }
    }

    return Promise.reject(new Error("Unhandled request error"));
  }
  private replaceQueryParams(
    url: string,
    params: Record<string, string | number | boolean>
  ): string {
    const parseParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== undefined &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key] !== false
      )
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");

    return `${this.baseUrl}${url}${parseParams ? `?${parseParams}` : ""}`;
  }

  setAccessToken(token: string) {
    this["X-Access-Token"] = token;
  }

  setAccessResource(resource: string) {
    this["X-Access-Resource"] = resource;
  }

  setCustomToken(token: string) {
    this.custom_token = token;
  }

  async get<T>(
    url: string,
    params: Record<string, string | number | boolean> = {},
    signal?: AbortSignal,
    options?: RequestInit
  ): Promise<T> {
    return this.request<T>(this.replaceQueryParams(url, params), {
      method: "GET",
      next: {
        revalidate: 1,
      },
      signal,
      ...options,
    });
  }

  async post<T>(
    url: string,
    body?: Record<string, string | number | boolean>
  ): Promise<T> {
    return this.request<T>(`${this.baseUrl}${url}`, {
      method: "POST",
      body: JSON.stringify(body),
    });
  }

  async patch<T>(
    url: string,
    body?: Record<string, string | number | boolean>
  ): Promise<T> {
    return this.request<T>(`${this.baseUrl}${url}`, {
      method: "PATCH",
      body: JSON.stringify(body),
    });
  }

  async put<T>(
    url: string,
    body?: Record<string, string | number | boolean>
  ): Promise<T> {
    return this.request<T>(`${this.baseUrl}${url}`, {
      method: "PUT",
      body: JSON.stringify(body),
    });
  }
}

const Client = new HttpClient(process.env.NEXT_PUBLIC_API_URL as string);
const http = new HttpClient(
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/api"
    : (`${process.env.NEXT_PUBLIC_BASE_URL}/api` as string)
);

export { http };
export default Client;
