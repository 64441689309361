import Link from "next/link";
import {
  Card,
  CardContent,
  CardMedia,
  SxProps,
  Typography,
  Box,
} from "@mui/material";

import { ResourceModelType } from "@/shared/api/resources";

import colors from "@/lib/palette";

type Props = {
  resource: ResourceModelType;
  disbleRowResponsive?: boolean;
  sx?: SxProps;
  onClickBadge?: () => void;
};

const Badge = ({
  children,
  prevent,
  sx,
}: {
  children: React.ReactNode;
  prevent?: React.ReactNode;
  sx?: SxProps;
}) => (
  <Box
    padding={"4px 16px"}
    bgcolor={"rgba(241, 243, 255, 0.5)"}
    sx={{
      backdropFilter: "blur(8px)",
      position: "absolute",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      zIndex: 3,
      ...sx,
    }}
  >
    {prevent && prevent}

    <Typography
      component={"span"}
      color={colors.blue}
      fontSize={{
        xs: "11px",
        md: "12px",
      }}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "inline-block",
      }}
    >
      {children}
    </Typography>
  </Box>
);

function ResourceCardComponent({
  resource,
  disbleRowResponsive,
  sx,
  onClickBadge,
}: Props) {
  const { keywords } = resource;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        border: 1,
        borderColor: "#E6E9ED",
        borderRadius: "8px",
        position: "relative",
        "&:hover": {
          boxShadow: "0px 16px 20px -12px rgba(106, 135, 255, 0.2)",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          background:
            "linear-gradient(232.13deg, #DAE6EF 5.02%, #FFFFFF 96.35%)",
          maxHeight: {
            xs: 164,
            sm: 198,
            md: 280,
          },
          position: "relative",
          flex: {
            xs: "1 0 auto",
            md: "auto",
          },
        }}
      >
        <Link
          href={`/resource/${resource.id}`}
          prefetch
          style={{ display: "block" }}
        >
          <Badge
            sx={{
              display: "flex",
              alignItems: "center",
              top: 0,
              left: 0,
            }}
            prevent={
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={{
                  xs: "16px",
                  md: "24px",
                }}
                height={{
                  xs: "16px",
                  md: "24px",
                }}
                mr={"8px"}
                borderRadius={"50%"}
                overflow={"hidden"}
              >
                <CardMedia
                  component="img"
                  image={
                    resource.company?.image_path ||
                    "/icons/company-default-img.png"
                  }
                  width={24}
                  height={24}
                  alt={resource.company?.name || "No company name"}
                  sx={{
                    objectFit: "contain",
                  }}
                />
              </Box>
            }
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: colors.black700,
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                whiteSpace: "pre-wrap",
                fontWeight: 500,
              }}
            >
              {resource.company?.name || "No company name"}
            </Typography>
          </Badge>

          <Badge
            sx={{
              transform: "translateY(-50%)",
              top: "100%",
              left: 0,
            }}
          >
            {resource.resource_type}
          </Badge>

          <Box
            sx={{
              margin: "0 auto",
              height: {
                xs: 164,
                sm: 198,
                md: 280,
              },
              maxHeight: "100%",
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              alt={resource?.name || "Title"}
              sx={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                objectFit: "contain",
                margin: "0 auto",
              }}
              image={
                resource?.source_main_img_url ||
                "/resource/resource-empty-picture.svg"
              }
            />
          </Box>
        </Link>
      </Box>

      <Box
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <CardContent
          sx={{
            padding: {
              xs: "16px 16px 8px",
              md: "20px 20px 24px",
            },
            paddingBottom: {
              xs: "8px !important",
              md: "24px",
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Link href={`/resource/${resource.id}`}>
            <Typography
              component="h4"
              variant="h4"
              fontWeight={700}
              title={resource?.name || "Title"}
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: {
                  xs: 2,
                  sm: 3,
                  md: 4,
                },
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                transition: "color 0.2s ease-in-out",
                "&:active": {
                  color: colors.blue,
                },
              }}
            >
              {resource?.name || "Title"}
            </Typography>
          </Link>

          <Box
            sx={{
              flexDirection: "row",
              flexWrap: "wrap",
              paddingTop: 2,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
              marginTop: "auto",
              wordBreak: "break-word",
              width: {
                xs: disbleRowResponsive ? "49vw" : "100%",
                md: "100%",
              },
              lineHeight: {
                xs: "20px",
                md: "auto",
              },
            }}
          >
            {keywords &&
              Array.isArray(keywords) &&
              keywords?.map((keyword: string) => {
                return (
                  <Box
                    key={`keyword_${keyword}`}
                    component="span"
                    sx={{
                      "&:after": {
                        content: "'•'",
                        color: colors.black700,
                        padding: "0 4px",
                        fontSize: "14px",
                      },
                      "&:last-child": {
                        "&:after": {
                          content: "''",
                        },
                      },
                    }}
                  >
                    <Link
                      href={`/library?type=resources&keywords=${keyword}`}
                      onClick={onClickBadge}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: colors.blue,
                          fontWeight: 500,
                          fontSize: {
                            xs: "12px",
                            md: "14px",
                          },
                          wordBreak: "keep-all",

                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {keyword}
                      </Typography>
                    </Link>
                  </Box>
                );
              })}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
}

export default ResourceCardComponent;
