import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React from "react";

function PasswordInputComponent(props: TextFieldProps) {
  const [password, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Box>
      <OutlinedInput
        type={password ? "text" : "password"}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={props.error}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
        required={props.required}
        name={props.name}
        ref={props.ref}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={
                password ? "hide the password" : "display the password"
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {!password ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      {props.helperText && (
        <Typography
          component={"p"}
          sx={{
            color: "#d32f2f",
            fontWeight: 400,
            fontSize: "12px !important",
            lineHeight: 1.66,
            textAlign: "left",
            marginTop: "3px",
            marginBottom: 0,
            marginLeft: "14px",
            marginRight: "14px",
          }}
        >
          {props.helperText}
        </Typography>
      )}
    </Box>
  );
}

export default PasswordInputComponent;
