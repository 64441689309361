import React, { useMemo } from "react";
import { Box, SxProps } from "@mui/material";
import colors from "@/lib/palette";

type HighlightedTextProps = {
  text: string;
  highlight: string;
  sx?: SxProps;
};

const HiglightedText = ({ text, highlight, sx }: HighlightedTextProps) => {
  const parts = useMemo(() => {
    return text.split(new RegExp(`(${highlight})`, "gi"));
  }, [text, highlight]);

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <Box
            component={"mark"}
            bgcolor={"transparent"}
            key={index}
            sx={{ fontWeight: 700, color: colors.blue, ...sx }}
          >
            {part}
          </Box>
        ) : (
          part
        )
      )}
    </>
  );
};

export default HiglightedText;

// import React from "react";
// import { Box, SxProps } from "@mui/material";
// import colors from "@/lib/palette";

// const higlightedText = (text: string, highlight: string, sx?: SxProps) => {
//   const parts = text.split(new RegExp(`(${highlight})`, "gi"));
//   return (
//     <>
//       {parts.map((part, index) =>
//         part.toLowerCase() === highlight.toLowerCase() ? (
//           <Box
//             component={"mark"}
//             bgcolor={"transparent"}
//             key={index}
//             sx={{ fontWeight: 700, color: colors.blue, ...sx }}
//           >
//             {part}
//           </Box>
//         ) : (
//           part
//         )
//       )}
//     </>
//   );
// };

// export default higlightedText;
